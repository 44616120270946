import React, { useState, useEffect } from 'react';
import './Header.css';
import logosbg from '../Assets/Resources/1_Logos/Landing Batman Run_Mesa de trabajo 1.png';
import logo85yrs from '../Assets/Resources/1_Logos/Logo 85años_Mesa de trabajo 1.png';
import logobatmanrun from '../Assets/Resources/1_Logos/Logo Carrera_Mesa de trabajo 1.png';

const Header = ({ onContactClick, onFAQClick }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [estaFixed, setEstaFixed] = useState(false);
  const [estaFixedMobil, setFixedMobil] = useState(false);


  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 100) {
        setEstaFixed(true);
        // console.log('Estoy entrando a mi Yoffset')
        setFixedMobil(true);
      } else {
        setEstaFixed(false);
        setFixedMobil(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };

  }, []);

  const Scrolltocomollegar = () =>  {
    const comollegarSection = document.getElementById('comollegar');
    comollegarSection.scrollIntoView({behavior: 'smooth'});
  };

  const Scrolltoinfo = () =>  {
    const infoSection = document.getElementById('info');
    infoSection.scrollIntoView({behavior: 'smooth'});
  };

  return (
    <>
      <header className="header desktop-header">
        <nav className={`navbar ${estaFixed ? 'fixed' : ''}`}>
        <div className={"logo-background-container"}>
          <img src={logosbg} alt="Background" className="background-image" />
          <div className="logo-container">
            <img src={logobatmanrun} alt="Logo Batman Run" className="header-logo" />
          </div>
        </div>
          <ul className="nav-list">
            <li className="nav-item"><a href="#">INICIO</a></li>
            <li className="nav-item"><a href="#" onClick={Scrolltocomollegar}>CIRCUITOS</a></li>
            <li className="nav-item"><a href="https://www.ticketmaster.cl/event/batman-run-2024-home">INSCRIPCIONES</a></li>
            <li className="nav-item"><a href="/BASES/BatmanRun 2024 - Bases y Reglamento Carrera 140824.pdf" download>BASES</a></li>
            <li className="nav-item"><a href="#" onClick={onFAQClick}>FAQ</a></li>
            <li className="nav-item"><a href="#" onClick={onContactClick}>CONTACTO</a></li>
          </ul>
        </nav>
      </header>

      <header className="header mobile-header">
        <div className="logo-background-container">
          <img src={logosbg} alt="Background" className="background-image" />
          <div className="logo-container">
            <img src={logobatmanrun} alt="Logo Batman Run" className="header-logo" />
            <img src={logo85yrs} alt="Logo 85 Years" className="header-logo" />
          </div>
        </div>
        <button className={`menu-toggle ${estaFixedMobil ? 'fixed' : ''}`} onClick={toggleMenu}>MENU</button>
        <nav className={`navbar ${isMenuOpen ? 'open' : ''}`}>
          <ul className={`nav-list  ${estaFixedMobil ? 'fixed' : ''}`}>
            <li className="nav-item"><a href="#">INICIO</a></li>
            <li className="nav-item"><a href="#" onClick={Scrolltocomollegar}>CIRCUITOS</a></li>
            <li className="nav-item"><a href="https://www.ticketmaster.cl/event/batman-run-2024-home">INSCRIPCIONES</a></li>
            <li className="nav-item"><a href="/BASES/BatmanRun 2024 - Bases y Reglamento Carrera 140824.pdf" download>BASES</a></li>
            <li className="nav-item"><a href="#" onClick={onFAQClick}>FAQ</a></li>
            <li className="nav-item"><a href="#" onClick={onContactClick}>CONTACTO</a></li>
          </ul>
        </nav>
      </header>
    </>
  );
}

export default Header;
