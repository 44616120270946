import React, { useState } from 'react';
import PreguntasyRespuestas from '../Assets/Resources/FAQS/Preguntas y respuestas.json';
import LegalImage from '../Assets/Resources/8_Legales/Legales_Mesa de trabajo 1.png';
import './Preguntasfrecuantes.css';

const Preguntasfrecuentes = ({ onBackClick }) => {
  const [expandir, setExpandir] = useState(Array(PreguntasyRespuestas.length).fill(false));

  const Contenido = (index) => {
    setExpandir((expandeOno) =>
      expandeOno.map((key, id) => (id === index ? !key : key))
    );
  };

  return (
    <>
      <h1 className='Main-Title'>PREGUNTAS FRECUENTES</h1>
      <button className='form-back-button' onClick={onBackClick}> × </button>
      {PreguntasyRespuestas.map((item, key) => (
        <div key={key} className='node'>
          <div className='contenedorFAQ'>
            <h1 className='pregunta'>{item.pregunta}</h1>
            <button className='boton' onClick={() => Contenido(key)}>
              {expandir[key] ? '-' : '+'}
            </button>
            {expandir[key] && (
              <div
                className='respuesta'
                dangerouslySetInnerHTML={{ __html: item.respuesta }}
              ></div>
            )}
          </div>
        </div>
      ))}
      <div className="legale-container">
        <img src={LegalImage} alt="legale" className="legale-image" />
      </div>
    </>
  );
};

export default Preguntasfrecuentes;
