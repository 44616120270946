import React from 'react';
import './Main.css'; 
import SaveDate from '../Assets/Resources/3_Save the date/Save the date_Mesa de trabajo 1.png';
import ButtonInscribete from '../Assets/Resources/3_Save the date/Boton Inscribete_Mesa de trabajo 1.png';
import Circuitos from '../Assets/Resources/4_Circuitos/Circuitos_Mesa de trabajo 1.png';
import TextoCircuito from '../Assets/Resources/4_Circuitos/Texto_Mesa de trabajo 1.png';
import Kitbg from '../Assets/Resources/5_Kit de competencia/Background kit_Mesa de trabajo 1.png';
import Kititem2 from '../Assets/Resources/5_Kit de competencia/Kit de competencia_Mesa de trabajo 1.png';
import EntregadeKits from '../Assets/Resources/5_Kit de competencia/Entrega de Kits.png';
import ImageAgenda from '../Assets/Resources/6_Agenda/Agenda_Mesa de trabajo 1.png';
import SponsorImage from '../Assets/Resources/7_Sponsors/Marca Sponsors_Mesa de trabajo 1.png';
import LegalImage from '../Assets/Resources/8_Legales/Legales_Mesa de trabajo 1.png';
import Instagram from '../Assets/Resources/8_Legales/Instagram.png';
import Flecha from '../Assets/Resources/flecha.png';

const Main = () => {

  const HandleScroll = () => {
    const inicioSection = document.getElementById('info');
    inicioSection.scrollIntoView({behavior: 'smooth'});
  }

  return (
    <>
      <div className="main-container" id='info'>
        <img src={SaveDate} alt="Save the Date" className="save-date" />
        <button className="button-inscribete">
         <a href='https://www.ticketmaster.cl/event/batman-run-2024-home'> <img src={ButtonInscribete} alt="Button Inscribete" /> </a>
      <hr id='comollegar'/>
        </button>
      </div>

      <div className="circuitos-container" >
        <img src={TextoCircuito} alt="Boton Circuito" />    
        <img src={Circuitos} alt="Circuitos"/>  
      </div>

        {<img src={EntregadeKits} alt="Kit Entrega" className="kit-Entrega" />}
      <div className="kit-container">
        <img src={Kitbg} alt="Kit Background" className="kit-background" />
        <img src={Kititem2} alt="Kit Item 2" className="kit-item" />
      </div>

      <div className="agenda-container">
        <img src={ImageAgenda} alt="Agenda" className="agenda-image" />
      </div>

      <div className="sponsor-container">
        <img src={SponsorImage} alt="Sponsor" className="sponsor-image" />
        <div className='volver-inicio'>
          <button className='flecha-volver-inicio' onClick={HandleScroll}>
            <img src={Flecha} className='flecha' />
          </button>
      </div>
      </div>

      <div className="legale-container">
        <a href="https://www.instagram.com/batmanrun/"><img src={Instagram} alt='Instagram' className='instagram' /></a>
        <img src={LegalImage} alt="legale" className="legale-image" />
      </div>
    </>
  );
}

export default Main;
